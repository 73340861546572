// @ts-nocheck
import React, { useState, useEffect, useReducer } from 'react';
import Button from '/components/ui/Button';
import styled from 'styled-components';
import PageHeader from '/components/ui/PageHeader';
import theme from '/style/theme';
import { connect } from 'react-redux';
import actions from '/store/actions';
import Textbox from '/components/ui/Textbox';
import ButtonGroup from '/components/ui/ButtonGroup';
import InputGroup from '/components/ui/InputGroup';
import Label from '/components/ui/Label';
import Select from '/components/ui/Select';
import InputWrap from '/components/ui/InputWrap';
import MaxWidth from '/components/ui/MaxWidth';
import axios from 'axios';
import getConfig from 'next/config';
import getCountries from '/utils/getCountries';
import Checkbox from '/components/ui/Checkbox';
import Link from 'next/link';
import SmallDropzone from '/components/ui/SmallDropzone';
import checkEmailRule from '/utils/checkEmailRule';
import Modal from 'react-modal';
import Close from 'components/ui/Close';
import { transition } from '/style/mixin';
import useTranslation from "next-translate/useTranslation";
import Trans from "next-translate/Trans";

const { publicRuntimeConfig } = getConfig();

interface Props {
  authentication: any;
  uistatus: any;
  changeLoading: Function;
  getUser: Function;
  changeSweetAlert: Function;
  changeModalMember: Function;
}
interface ModelCountryItem {
  country_id: number;
  country_name: string;
}

const PageContent = styled.div`
  max-width: 590px;
  margin: 0 auto;
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
`;
const Row = styled.div`
  vertical-align: top;
  text-align: left;
  font-size: 18px;
  margin-bottom: 50px;
`;
const ContentTitle = styled.div`
  font-size: 18px;
  color: ${theme.dark_gy};
  font-weight: bold;
  margin-bottom: 10px;
  img {
    float: left;
    margin-right: 21px;
    margin-top: 3px;
    width: 18px;
    height: 18px;
    vertical-align: bottom;
  }
`;
const ContentText = styled.div`
  font-size: 15px;
  line-height: 1.53;
  padding-left: 40px;
`;
const ButtonWrap = styled.div`
  padding-top: 30px;
  border-top: 1px solid ${theme.line_gy};
`;
const OtherLink = styled.div`
  ${transition()}
  margin-top: 10px;
  text-align: center;
  color: ${theme.mid_gy};
  strong {
    ${transition()}
    color: ${theme.trazy_og};
    font-weight: ${theme.normal};
    cursor: pointer;
  }
  &:hover {
    color: ${theme.light_gy4};
    strong {
      color: ${theme.trazy_mint};
    }
  }
`;
const FormGroup = styled.div`
  text-align: left;
`;
const SubmitGroup = styled.div``;
const LabelStyled = styled(Label)`
  @media ${theme.sm} {
    width: 180px;
    text-align: left;
  }
`;
const LabelHorizontalStyled = styled(Label)`
  margin-top: 10px;
  margin-bottom: 0;
`;
const InputWrapStyled = styled(InputWrap)`
  @media ${theme.sm} {
    width: calc(100% - 180px);
  }
`;
const SubmitDescription = styled.div`
  margin-top: 30px;
  padding: 30px 0;
  border-top: 1px solid ${theme.line_gy};
  border-bottom: 1px solid ${theme.line_gy};
  color: ${theme.mid_gy};
  a {
    color: ${theme.brand};
  }
  label {
    padding-left: 23px;
    height: auto;
    .label {
      font-size: 14px;
      line-height: 1.54;
      &:before {
        margin-top: 4px;
      }
    }
  }
`;
const Description = styled.div`
  margin-top: 5px;
  font-size: 14px;
  color: ${theme.mid_gy};
`;
const ModalWrapper = styled.div`
  overflow: hidden;
  border-radius: 5px;
`;
const ModalHeader = styled.div`
  position: relative;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  color: white;
  padding: 50px 40px;
  background-image: linear-gradient(to bottom, ${theme.light_og}, ${theme.pastel_og});
`;
const ModalBody = styled.div`
  padding: 50px 40px;
  font-size: 15px;
  line-height: 1.6;
  background-color: #fff;
`;

const initial_state = {
  files: '',
  business_name: '',
  password: '',
  confirm_password: '',
  email: '',
  website_url: '',
  country: {},
  contact_number: '',
  contact_person: '',
  contact_person_position: '',
  agree_policy: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_INPUT':
      return {
        ...state,
        [action.name]: action.value,
      };
    default:
      return state;
  }
};

const BlockBusinessNonMember: React.FC<Props> = ({ uistatus, changeLoading, changeSweetAlert, changeModalMember }) => {
  const [show_signup, setShowSignup] = useState(false);
  const [form, dispatch] = useReducer(reducer, initial_state);
  const [nationality_options, setNationalityOptions] = useState([]);
  const [nationality_placeholder, setNationalityPlaceholder] = useState('Loading...');
  const [show_modal_welcome, setShowModalWelcome] = useState(false);
  const { files } = form;
  const { t } = useTranslation('home');
  // functions
  const getNationalityOptions = async (): Promise<void> => {
    const raw_nationality_options = await getCountries();
    if (raw_nationality_options && raw_nationality_options.length) {
      const new_nationality_options = raw_nationality_options.map((item: ModelCountryItem) => {
        return { label: item.country_name, value: item.country_id };
      });
      setNationalityOptions(new_nationality_options);
      setNationalityPlaceholder(t('business.form.country'));
    }
  };
  const setFiles = value => {
    dispatch({
      type: 'CHANGE_INPUT',
      name: 'files',
      value,
    });
  };
  const removeFileByIndex = index => {
    if (files.length) {
      const _files = [...files];
      _files.splice(index, 1);
      setFiles(_files);
    }
  };

  // handlers
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({
      type: 'CHANGE_INPUT',
      name,
      value,
    });
  };
  const handleChangeCountry = (value: any) => {
    dispatch({
      type: 'CHANGE_INPUT',
      name: 'country',
      value,
    });
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const {
      files,
      business_name,
      email,
      password,
      confirm_password,
      website_url,
      country,
      contact_number,
      contact_person,
      contact_person_position,
      agree_policy,
    } = form;
    if (
      !business_name ||
      !email ||
      !country?.value ||
      !contact_number ||
      !contact_person ||
      !contact_person_position ||
      !files?.length ||
      !password ||
      !confirm_password
    ) {
      changeSweetAlert('Oops! Please fill in all the fields.');
      return;
    }
    if (!agree_policy) {
      changeSweetAlert('Oops! Please make sure you agree to our Terms of Service and Privacy Policy.');
      return;
    }
    if (!checkEmailRule(email)) {
      changeSweetAlert('Please type in a proper email address.');
      return;
    }
    if (password.length < 6) {
      changeSweetAlert({
        title: 'Oops! Please set your password with more than 6 characters.',
        show: true,
      });
      return;
    }
    if (confirm_password !== password) {
      changeSweetAlert({
        title: 'Passwords do not match. Please try again.',
        show: true,
      });
      return;
    }
    changeLoading(true);
    const formdata = new FormData();
    formdata.append('business_name', business_name);
    formdata.append('country_id', country ? country.value : '');
    formdata.append('country_name', country ? country.label : '');
    formdata.append('email', email);
    formdata.append('password', password);
    formdata.append('website_url', website_url || '');
    formdata.append('contact_number', contact_number);
    formdata.append('contact_person', contact_person);
    formdata.append('contact_person_position', contact_person_position);
    if (files?.length) {
      files.forEach(file => formdata.append('files', file));
    }
    axios
      .post(`${publicRuntimeConfig.API_PATH}/business/register`, formdata, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then(() => {
        changeLoading(false);
        setShowModalWelcome(true);
      })
      .catch(err => {
        changeLoading(false);
        changeSweetAlert({
          show: true,
          title: err?.response?.data?.detail ? err.response.data.detail : 'Unknown Error.',
        });
      });
  };
  const handleCloseModalWelcome = () => {
    setShowModalWelcome(false);
    window.location.href = '/';
  };

  // hooks
  useEffect(() => {
    if (show_signup) {
      getNationalityOptions();
    }
  }, [show_signup]);
  return (
    <>
      <PageHeader
        title={t('business.title')}
        subtitle={t('business.subtitle')}
        background_image={require('/public/images/pageheader/bg-business.jpg')}
        background_image_mobile={require('/public/images/pageheader/bg-business-mobile.jpg')}
      />
      <PageContent>
        {show_signup ? (
          <form onSubmit={handleSubmit} noValidate className="form-signup">
            <FormGroup>
              {/* <MaxWidth maxwidth="470px"> */}
              <InputGroup>
                <LabelStyled required htmlFor="business_name" horizontalLabel>
                  {t('business.form.business-name')}
                </LabelStyled>
                <InputWrapStyled horizontalLabel>
                  <Textbox id="business_name" name="business_name" onChange={handleChange} />
                </InputWrapStyled>
              </InputGroup>
              <InputGroup>
                <LabelStyled required htmlFor="email" horizontalLabel>
                  {t('business.form.email')}
                </LabelStyled>
                <InputWrapStyled horizontalLabel>
                  <Textbox id="email" name="email" onChange={handleChange} autoComplete="email" />
                </InputWrapStyled>
              </InputGroup>
              <InputGroup>
                <LabelStyled required htmlFor="password" horizontalLabel>
                  {t('business.form.password')}
                </LabelStyled>
                <InputWrapStyled horizontalLabel>
                  <Textbox id="password" name="password" onChange={handleChange} can_switch_type />
                </InputWrapStyled>
              </InputGroup>
              <InputGroup>
                <LabelStyled required htmlFor="confirm_password" horizontalLabel>
                  {t('business.form.confirm-password')}
                </LabelStyled>
                <InputWrapStyled horizontalLabel>
                  <Textbox id="confirm_password" name="confirm_password" onChange={handleChange} can_switch_type />
                </InputWrapStyled>
              </InputGroup>
              <InputGroup>
                <LabelStyled htmlFor="website_url" horizontalLabel>
                  {t('business.form.website')}
                </LabelStyled>
                <InputWrapStyled horizontalLabel>
                  <Textbox id="website_url" name="website_url" onChange={handleChange} autoComplete="website_url" />
                </InputWrapStyled>
              </InputGroup>
              <InputGroup>
                <LabelStyled required htmlFor="country" horizontalLabel>
                  {t('business.form.headquarter')}
                </LabelStyled>
                <InputWrapStyled horizontalLabel>
                  <Select
                    id="country"
                    options={nationality_options}
                    onChange={handleChangeCountry}
                    placeholder={nationality_placeholder}
                  />
                </InputWrapStyled>
              </InputGroup>
              <InputGroup>
                <LabelStyled required htmlFor="contact_number" horizontalLabel>
                  {t('business.form.contact-number')}
                </LabelStyled>
                <InputWrapStyled horizontalLabel>
                  <Textbox id="contact_number" name="contact_number" onChange={handleChange} />
                </InputWrapStyled>
              </InputGroup>
              <InputGroup>
                <LabelStyled required htmlFor="contact_person" horizontalLabel>
                  {t('business.form.contact-person')}
                </LabelStyled>
                <InputWrapStyled horizontalLabel>
                  <Textbox id="contact_person" name="contact_person" onChange={handleChange} />
                </InputWrapStyled>
              </InputGroup>
              <InputGroup>
                <LabelStyled required htmlFor="contact_person_position" horizontalLabel>
                  {t('business.form.contact-person-position')}
                </LabelStyled>
                <InputWrapStyled horizontalLabel>
                  <Textbox id="contact_person_position" name="contact_person_position" onChange={handleChange} />
                </InputWrapStyled>
              </InputGroup>
              <InputGroup>
                <LabelHorizontalStyled required htmlFor="file">
                  {t('business.form.document')}
                </LabelHorizontalStyled>
                <InputWrap>
                  <SmallDropzone
                    files={files || []}
                    setFiles={setFiles}
                    removeFileByIndex={removeFileByIndex}
                    accept="image/jpg, image/jpeg, image/png, application/pdf"
                  />
                  <Description>{t('business.form.document-description')}</Description>
                </InputWrap>
              </InputGroup>
              <SubmitDescription>
                <Checkbox id="agree_policy" name="agree_policy" onChange={handleChange} small>
                  <Trans
                    i18nKey="home:business.form.signup-agree"
                    components={{
                      tos_link: <Link href="/main/footer/termsofservice">
                        <a target="_blank" rel="noreferrer noopener">{t('home:business.form.tos')}</a>
                      </Link>,
                      pp_link: <Link href="/main/footer/privacypolicy">
                        <a target="_blank" rel="noreferrer noopener">{t('home:business.form.privacy-policy')}</a>
                      </Link>,
                    }}
                  >
                  </Trans>
                </Checkbox>
              </SubmitDescription>
              <SubmitGroup>
                <ButtonGroup>
                  <Button
                    submit
                    disabled={uistatus.submitting}
                    style={{ maxWidth: '335px', margin: '0 auto' }}
                    mint
                    full
                  >
                    {t('business.form.signup')}
                  </Button>
                </ButtonGroup>
              </SubmitGroup>
              {/* </MaxWidth> */}
            </FormGroup>
            <Modal
              isOpen={show_modal_welcome}
              onRequestClose={handleCloseModalWelcome}
              shouldCloseOnOverlayClick={false}
              style={{
                content: {
                  outline: 0,
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  overflowX: 'hidden',
                  padding: '20px',
                  backgroundColor: 'transparent',
                  border: 0,
                  maxWidth: '450px',
                  width: '100%',
                },
                overlay: {
                  backgroundColor: 'rgba(0,0,0,0.3)',
                  zIndex: 200,
                },
              }}
            >
              <ModalWrapper>
                <ModalHeader>
                  <Trans
                    i18nKey="home:business.thank-you-title"
                    components={{
                      br: <br />
                    }}
                  >
                  </Trans>
                  <Close onClick={handleCloseModalWelcome} />
                </ModalHeader>
                <ModalBody>
                  {t('business.thank-you-description')}
                </ModalBody>
              </ModalWrapper>
            </Modal>
          </form>
        ) : (
          <>
            <Row>
              <ContentTitle>
                <img src={require('/public/images/ui/business-check.png')} /> {t('business.exclusive-discount')}
              </ContentTitle>
              <ContentText>{t('business.exclusive-discount-description')}</ContentText>
            </Row>
            <Row>
              <ContentTitle>
                <img src={require('/public/images/ui/business-check.png')} /> {t('business.instant-booking')}
              </ContentTitle>
              <ContentText>{t('business.instant-booking-description')}</ContentText>
            </Row>
            <Row>
              <ContentTitle>
                <img src={require('/public/images/ui/business-check.png')} /> {t('business.end-to-end')}
              </ContentTitle>
              <ContentText>
                <Trans
                  i18nKey="home:business.end-to-end-description"
                  components={{
                    brsm: <br className="show-sm" />
                  }}
                >
                </Trans>
              </ContentText>
            </Row>
            <ButtonWrap>
              <Button onClick={() => setShowSignup(true)} gradient full>
                {t('business.join-now')}
              </Button>
            </ButtonWrap>
          </>
        )}
        <OtherLink>
          <Trans
            i18nKey="home:business.already-member"
            components={[
              <strong
                onClick={() => {
                  changeModalMember({
                    show: true,
                    type: 'login',
                  });
                }}
              ></strong>
            ]}
          >
          </Trans>
        </OtherLink>
      </PageContent>
    </>
  );
};

export default connect(({ uistatus }) => ({ uistatus }), {
  changeModalMember: actions.changeModalMember,
  changeLoading: actions.changeLoading,
  changeSweetAlert: actions.changeSweetAlert,
})(BlockBusinessNonMember);
