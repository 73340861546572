// @ts-nocheck
import React, { useEffect } from 'react';
import { NextSeo } from 'next-seo';
import Layout from '/components/Layout';
import BlockCategories from '/components/Home/BlockCategories';
import BlockFeatures from '/components/Home/BlockFeatures';
import BlockActivities from '/components/Home/BlockActivities';
import BlockFixedBanner from '/components/Home/BlockFixedBanner';
import { connect } from 'react-redux';
import actions from '/store/actions';
import { getCookie } from '/utils/cookie';
import TypeCouponCampaign from '/type/TypeCouponCampaign';
import TypeCampaignBanner from '/type/TypeCampaignBanner';
import theme from '/style/theme';
import LayoutBadConnection from '/components/LayoutBadConnection';
import BlockFeaturedBlog from './BlockFeaturedBlog';
import BlockBusinessCover from './BlockBusinessCover';
import BlockBusinessNonMember from './BlockBusinessNonMember';
import BlockBusinessRecentlyBooked from './BlockBusinessRecentlyBooked';
import Router from 'next/router';
import getParamsObject from '/utils/getParamsObject';
import BlockCarousel from "./BlockCarousel";

interface QueryObject {
  redirect: string;
}
interface Props {
  data: DataObject;
  query: QueryObject;
  changeModalMember: (value: object) => void;
}
interface DataObject {
  meta_title: string;
  meta_description: string;
  og_title: string;
  og_description: string;
  og_image: string;
  keywords: string;
  slider_list: Array<object>;
  reserve_num: number;
  category_list: Array<object>;
  city_list: Array<object>;
  city_list_title: string;
  city_list_subtitle: string;
  activity_list1: Array<object>;
  activity_list_title1: string;
  activity_list_subtitle1: string;
  activity_list_more1: string;
  activity_list2: Array<object>;
  activity_list_title2: string;
  activity_list_subtitle2: string;
  activity_list_more2: string;
  activity_list3: Array<object>;
  activity_list_title3: string;
  activity_list_subtitle3: string;
  activity_list_more3: string;
  blog_list: Array<object>;
  blog_list_title: string;
  blog_list_subtitle: string;
  blog_list_more: string;
  coupon_campaign: TypeCouponCampaign;
  campaign: TypeCampaignBanner;
}

const LayoutHomeBusiness = ({
  // from parent
  data,
  query,
  // from store
  changeModalMember,
  authentication,
}: Props) => {
  const showModalByQuery = _query => {
    if (_query && _query.redirect) {
      changeModalMember({
        type: 'login',
        show: true,
      });
    }
    if (_query && _query.reset_password) {
      changeModalMember({
        type: 'password',
        show: true,
      });
    }
  };
  const onRouteChange = url => {
    const _query = getParamsObject(url);
    showModalByQuery(_query);
  };

  useEffect(() => {
    showModalByQuery(query);

    Router.events.on('routeChangeStart', onRouteChange);
    return () => {
      Router.events.off('routeChangeStart', onRouteChange);
    };
  }, []);

  const is_logged_in = !!authentication.user;

  return data ? (
    <Layout padding_top={0} padding_bottom={0} mobile_header_transparent>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(
            {
              '@context': 'http://schema.org',
              '@type': 'WebSite',
              name: 'Trazy',
              alternateName: 'Trazy.com',
              url: 'https://business.trazy.com',
              potentialAction: {
                '@type': 'SearchAction',
                target: 'https://business.trazy.com/search?keyword={search_term_string}',
                'query-input': 'required name=search_term_string',
              },
            },
            null,
            2,
          ),
        }}
      />
      <NextSeo
        title={data.meta_title}
        description={data.meta_description}
        openGraph={{
          title: data.og_title,
          description: data.og_description,
          url: 'https://business.trazy.com/',
          images: [
            {
              url: data.og_image,
            },
          ],
        }}
        canonical={'https://business.trazy.com/'}
        additionalMetaTags={[
          {
            name: 'keywords',
            content: data.keywords,
          },
        ]}
      />
      <h2 className="sr-only">Main page</h2>
      {is_logged_in ? (
        <>
          { data.slider_list ? (
            <BlockCarousel data={data.slider_list} />
          ) : <BlockBusinessCover /> }
          <BlockFeatures data={data.reserve_num || 0} />
          <BlockCategories data={data.category_list} />
          {data.recent_booked_list && data.recent_booked_list.length ? (
            <BlockBusinessRecentlyBooked data={data.recent_booked_list} />
          ) : null}
          <BlockActivities
            data={data.activity_list1}
            title={data.activity_list_title1}
            subtitle={data.activity_list_subtitle1}
            more={data.activity_list_more1}
            no_border
          />
          <BlockActivities
            data={data.activity_list2}
            title={data.activity_list_title2}
            subtitle={data.activity_list_subtitle2}
            more={data.activity_list_more2}
          />
          <BlockActivities
            data={data.activity_list3}
            title={data.activity_list_title3}
            subtitle={data.activity_list_subtitle3}
            more={data.activity_list_more3}
          />
          {data.blog_list && data.blog_list.length ? (
            <BlockFeaturedBlog
              data={data.blog_list}
              title={data.blog_list_title}
              subtitle={data.blog_list_subtitle}
              more={data.blog_list_more}
            />
          ) : null}
          {!getCookie('tr_homebanner_closed') && data.coupon_campaign ? (
            <BlockFixedBanner data={data.coupon_campaign} />
          ) : null}
        </>
      ) : (
        <BlockBusinessNonMember />
      )}
    </Layout>
  ) : (
    <LayoutBadConnection />
  );
};

export default connect(
  state => ({
    authentication: state.authentication,
  }),
  {
    changeModalMember: actions.changeModalMember,
  },
)(LayoutHomeBusiness);
