import theme from '/style/theme';
import styled from 'styled-components';
import { backgroundCover } from '/style/mixin';

interface WrapperProps {
  background_image?: string;
  background_image_mobile?: string;
  has_tab?: boolean;
  vertical_middle?: boolean;
}
interface Props extends WrapperProps {
  title?: string;
  subtitle?: string;
}

const Wrapper = styled.div<WrapperProps>`
  height: 180px;
  padding: 105px 0 45px;
  text-align: center;
  color: #fff;
  background-color: ${theme.brand};
  background-image: linear-gradient(
    to right,
    ${theme.light_og} 0%,
    ${theme.trazy_og}
  );
  @media ${theme.sm} {
    height: 200px;
    padding: 112px 0 52px;
  }
  .page-title {
    font-size: 25px;
    line-height: 1.2;
    letter-spacing: -0.2px;
    font-weight: ${theme.bold};
    @media ${theme.sm} {
      font-size: 30px;
      line-height: 1.17;
    }
  }
  ${props =>
    props.background_image &&
    `
      height: 220px;
      padding: 125px 0 65px;
      background-image: url(${props.background_image});
      ${backgroundCover}
      .page-title{
        line-height: normal;
        letter-spacing: normal;
      }
      @media ${theme.sm} {
        height: 300px;
        padding: 163px 0 102px;
        ${props.has_tab &&
          `
          height: 200px;
          padding: 80px 0;
        `}
      }
    `}
  ${props =>
    props.background_image_mobile &&
    `
      @media ${theme.xs_max} {
        background-image: url(${props.background_image_mobile});
      }
  `}
  ${props =>
    props.vertical_middle &&
    `
    @media ${theme.xs_max} {
      height: 200px;
      padding-top: 82px;
      .page-title {
        font-size: 30px;
      }
    }
  `}
`;
const PageHeader = ({
  title,
  subtitle,
  background_image,
  background_image_mobile,
  vertical_middle,
  has_tab,
  ...rest
}: Props) => {
  return (
    <Wrapper
      background_image={background_image}
      background_image_mobile={background_image_mobile}
      vertical_middle={vertical_middle}
      has_tab={has_tab}
      {...rest}
    >
      {title && <div className="page-title">{title}</div>}
      {subtitle && <div className="page-subtitle">{subtitle}</div>}
    </Wrapper>
  );
};

export default PageHeader;
