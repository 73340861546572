import axios from 'axios';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

const getCountries = async () => {
  const res = await axios.get(`${publicRuntimeConfig.API_PATH}/base-countries`);
  if (!res) return [];
  return res.data;
};

export default getCountries;
